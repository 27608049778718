footer {
  background-color: var(--gray-color);
  margin-top: 2em !important;
  .main-footer {
    padding: 2.5em 0;
    h5 {
      font-size: 1.2em;
      margin-bottom: 1em;
    }
    ul {
      li {
        a {
          color: #666;
          display: block;
          font-size: 16px;
          transition: all .2s ease;
          &:hover {
            color: var(--principal-color);
          }
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    
  }
  .footer__bottom {
    background-color: var(--principal-color);
    color: var(--gray-color);
    padding: 15px 0;
    p {
      font-size: 14px;
    }
  }
  .col-sm + .col-sm {
    h5 {
      margin-top: 1.25em;
    }
  }
}

@media (min-width: 576px) {
  footer .main-footer ul li a {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  footer {
    .col-sm + .col-sm {
      h5 {
        margin-top: auto;
      }
    }
  }
}