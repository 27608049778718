header {
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0,0,0,.15);
  position: relative;
  z-index: 20;
  .header__top {
    background-color: var(--principal-color);
    color: #FFF;
    line-height: 1;
    padding: 12px 0;
    text-align: right;
    font-weight: 500;
    i {
      font-size: 18px;
      position: relative;
      top: 3px;
      line-height: 0;
    }
  }
  .main-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .logotipo {
    align-items: center;
    display: flex;
    width: 220px;
    padding: 20px 0;
    img {
      max-width: 100%;
    }
  }
  .btn-menu {
    display: block;
  }
  nav {
    position: absolute;
    top: 80px;
    background-color: #FFF;
    box-shadow: 0 6px 12px rgba(0,0,0,.15);
    right: 0;
    z-index: 100;
    border-radius: 4px;
    display: none;
    > li {
      display: block;
      width: 220px;
      a {
        color: var(--principal-color);
        font-size: 14px;
        font-weight: 500;
      }
    }
    li {
      position: relative;
      a {
        padding: 20px 35px;
        i {
          position: absolute;
          right: 15px;
          top: 20px;
        }
      }
      .submenu {
        box-shadow: 0 5px 15px rgba(0,0,0,.15);
        border-radius: 4px;
        display: none;
        left: 0;
        position: absolute;
        z-index: 1000;
        li {
          background-color: #FFF;
          transition: all .25s ease;
          a {
            padding: 15px 25px;
            width: 200px;
            color: #5f5f5f;
            font-weight: 400;
            i {
              top: 12px;
            }
          }
          &:not(:last-child) {
            border-bottom: 1px solid #f0f0f0;
          }
          &:hover {
            background-color: var(--principal-color);
            > a {
              color: #FFF;
            }
          }
        }
        .submenu {
          top: 0;
          left: 200px;
        }
      }
      &:hover > .submenu {
        display: block;
      }
    }
  }
  .contador--cesta {
    background-color: #ffc107;
    color: #19226d;
    font-weight: 600;
    text-align: center;
    height: 20px;
    width: 20px;
    position: absolute;
    border-radius: 10px;
    font-size: 11px;
    padding-top: 4px;
    line-height: 1;
    right: 15px;
    top: 30px;
  }
}

.menu {
  width: 220px;
  position: 'relative';
  overflow: 'auto';
  max-height: 300px;
  .contador--cesta {
    background-color: #ffc107;
    color: #19226d;
    font-weight: 600;
    text-align: center;
    height: 20px;
    width: 20px;
    position: absolute;
    border-radius: 10px;
    font-size: 11px;
    padding-top: 4px;
    line-height: 1;
    right: 20px;
    top: 15px;
  }
}

@media (min-width: 768px) {
  header {
    .logotipo {
      max-width: 300px;
      width: auto;
    }
  }
}

@media (min-width: 992px) {
  header {
    .logotipo {
      padding: 0;
    }
    .btn-menu {
      display: none;
    }
    nav {
      position: relative;
      top: 0;
      background-color: auto;
      box-shadow: none;
      right: 0;
      z-index: 100;
      border-radius: 0;
      display: block;
      li {
        display: inline-block;
        width: auto;
        a {
          cursor: pointer;
          display: block;
          padding: 30px 40px;
          i {
            right: 10px;
            top: 30px;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid #eaeaea;
        }
      }
    }
  }
}