:root {
  --principal-color: #19226D;
  --principal-highlight-color: #304FFE;
  --general-color: #333333;
  --gray-color: #f6f6f6;
  --font-base: "Helvetica Neue", Helvetica, sans-serif;
}
*, html {
  box-sizing: border-box;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
  v2.0 | 20110126
  License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1.5;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}

// Bootstrap Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Bootstrap Custom
@import "../node_modules/bootstrap/scss/grid";

html {
  background-color: var(--principal-color);
}

body {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 400;
  color: var(--general-color);
  background-color: #FFF;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--principal-color);
  line-height: 1.25;
}

h2 {
  font-size: 1.5em;
  &.page-title {
    margin-top: 2em;
    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 2px;
      background-color: var(--principal-color);
      margin-top: .75em;
      margin-bottom: 1.5em;
    }
  }
}

h3 {
  font-size: 1.25em;
  text-transform: capitalize;
  &.page-subtitle {
    margin-bottom: 1em;
  }
}

p + p {
  margin-top: 1em;
}

b {
  font-weight: 500;
}

.section {
  padding: 2em 0;
}

.mt-1 { margin-top: 1em; }
.mb-1 { margin-bottom: 1em }

.btn {
  background-color: #f6f6f6;
  border: none;
  border-radius: 4px;
  color: var(--principal-color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-base);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  outline: none;
  padding: 12px 24px;
  transition: all .1s ease;
  &:hover {
    background-color: var(--principal-color);
    color: #FFF;
  }
  &.btn-primary {
    background-color: var(--principal-color);
    color: #FFF;
    &:hover {
      background-color: var(--principal-highlight-color);
    }
  }
  &.btn-secondary {
    background-color: rgba(0,0,0,0);
    &:hover {
      color: var(--principal-highlight-color);
    }
  }
}

.list {
  li {
    margin-bottom: 1em;
    padding-left: 2em;
    position: relative;
    &:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: var(--principal-color);
      position: absolute;
      left: .5em;
      top: 0.6em;
    }
  }
}

.loadingBox {
  margin-top: 2em;
}

.footer__bottom-content {
  display: flex;
  justify-content: space-between;
}

.credit-card {
  li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 10px;
    }
    img {
      width: 36px;
    }
  }
}